import React from "react"
class Footer extends React.Component {
	render() {
		return   <div className="foot">
      <div className="card">
        <div className="card_img">
          <img style={{ width: '100px', height: '100px', maxWidth: '100px', flex: 1 }} src="https://jiatan-1254247596.file.myqcloud.com/7c02d1d47e9b9e75c712241bcf3aa890/5f6c35fc/2020/9/24/d8f63c5ce9aa48b995b0c105bfde3170_large.png" alt='价探PriceTag' />
        </div>
        <div className="explain">
          <p style={{ color: '#009E96', fontSize: '25px', marginBottom: '5px' }}>肯豆山</p>
          <p style={{ color: '#009E96', marginBottom: '5px' }}>Sass服务提供商</p>
          <p>肯豆山 成立于2016年</p>
          <p>专注为购物中心实现数字化转型</p>
          <p>冲破区域服务限制, 完成一二线业态下沉布局。实现精准</p>
          <p>流量变现。搭建链接购物中心和全国下沉市场用户的数字</p>
          <p>化服平台</p>
          <div className="tags">
            <div className="tags_left"></div>
            <div className="tags_right"></div>
          </div>
        </div>
        <div className="tab">
          <div className="tab_top">
            <div className="sign" style={{ marginLeft: 'auto' }} />
          </div>
          <div className="tab-bottom">
            <div className="sign" />
            <div className="sign" style={{ background: '#009E96' }} />
          </div>
        </div>
      </div>
      <div className="identifier">Copyright © 2023，上海蜜田科技有限公司  <a href="https://beian.miit.gov.cn">沪ICP备18011516号-1</a></div>
    </div>
	}
}
export default Footer
