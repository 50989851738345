import React from "react";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function App() {
  return  <div className="main">
            <Header />
            <Body />
            <Footer />
          </div>
}
