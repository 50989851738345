import React from "react"
class Header extends React.Component {
  linkTo = () => {
    window.location.href = 'http://www.jiatanpricetag.com/'
  }
	render() {
		return  <div className="header">
              <img src="https://jiatan-1254247596.file.myqcloud.com/7c02d1d47e9b9e75c712241bcf3aa890/5f6c35fc/2020/9/24/d8f63c5ce9aa48b995b0c105bfde3170_large.png" alt='价探PriceTag' />
              <div className="linkTags" style={{ marginTop: '-10px', cursor: 'pointer' }} onClick={this.linkTo.bind(this)}>
                <p style={{ color: '#000', fontSize: '23px', fontWeight: 500, marginBottom: '7px', lineHeight: '40px' }}>价探PriceTag</p>
                <div className="tags" style={{ marginBottom: 0, top: 'auto', bottom: 0, width: '100%' }}>
                  <div className="tags_left" style={{ width: '75%' }}></div>
                  <div className="tags_right" style={{ width: '20%', marginLeft: '5%' }}></div>
                </div>
              </div>
            </div>
	}
}
export default Header
