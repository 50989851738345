import React from "react"
class Body extends React.Component {
	render() {
		return  <div>
              <div className="content">
                <div className="content_left">
                  <p style={{ fontSize: '50px', marginBottom: '10px' }}>肯豆山</p>
                  <div className="split"></div>
                  <p style={{ fontSize: '30px', color: '#7e8285' }}>sass服务提供商</p>
                </div>
                <img style={{ marginLeft: 'auto', height: '700px', marginRight: '100px'  }} src="https://jiatan-1254247596.file.myqcloud.com/d89cf984af049fe522c8c8d948e532b5/5f6c0d30/2020/9/24/c862b72881e34a4c8474a0a10571ce2c_large.png" alt='价探官网' />
              </div>
              <div className="title">肯豆山用户数字化经营系统</div>
              <div className="content_bottom">
              <div className="bottom_card" style={{ paddingLeft: 0 }}>
                <img src="https://jiatan-1254247596.file.myqcloud.com/1337389a8421f745e7c4b2a123662507/5f6c10e2/2020/9/24/83c63d96439b49179cd505973239b1f1_large.png" alt='SCRM' />
                <p>SCRM</p>
                <p>轻量级 高性能 全渠道</p>
              </div>
              <div className="bottom_card">
                <img src="https://jiatan-1254247596.file.myqcloud.com/8ca6f53211895d2d7a7da01d4e4c8e0b/5f6c10f4/2020/9/24/5d244ffff1a645659463e67fe2fc311b_large.png" alt='移动端应用' />
                <p>移动端应用</p>
                <p>全场景 多功能 小程序</p>
              </div>
              <div className="bottom_card">
                <img src="https://jiatan-1254247596.file.myqcloud.com/ccd8aaa42562beaa975523f9e3bbf260/5f6c110a/2020/9/24/99ffabfddf3740ae8ee7763e1c738510_large.png" alt='数据中台' />
                <p>数据中台</p>
                <p>多维度 灵活搭建 深度挖掘</p>
              </div>
              <div className="bottom_card" style={{ paddingRight: 0 }}>
                <img src="https://jiatan-1254247596.file.myqcloud.com/51109527c6e4f319aea654f440c5b889/5f6c1116/2020/9/24/2e5ed374165b44a19013f760c939ece0_large.png" alt='价探PriceTag' />
                <p>智能营销</p>
                <p>全方位 自动化 个性化</p>
              </div>
            </div>
            </div>
	}
}
export default Body
